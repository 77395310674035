import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {createFetchCategoryRemove} from '../../../backend/apiCalls';
import {navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class DeleteCategory extends React.PureComponent {
    static propTypes = {
        categoryId: PropTypes.string.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        location: Location,
    };

    onFinishSuccessful = () => {
        const {location, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        reload([GLOBAL_DATA.CATEGORIES, GLOBAL_DATA.CATEGORY_TREE]);
        navigateToParametrized(location, null , {[QUERY_PARAMS.ID_PRODUCT_GROUP]: undefined});
    };

    render() {
        const {categoryId} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie produktovej kategórie</Trans>}>
                    <Button className="mx-1" type="danger" icon="delete" size="small"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.onFinishSuccessful}
            title={<Trans>Odstránenie kategórie</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete vymazať kategóriu?</Trans>,
                createFetchCategoryRemove(categoryId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Kategóriu sa nepodarilo odstrániť.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(locationHOC(DeleteCategory));